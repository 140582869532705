import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles/header.module.scss";

const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <div className={styles.headerWrap}>
      <h1>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div className={styles.headerLinks}>
        <Link className={styles.headerLink} to="/countries">
          countries
        </Link>
        <Link className={styles.headerLink} to="/about">
          about
        </Link>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
