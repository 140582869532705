/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "../styles/index.scss";

const Layout = ({ hideHeader, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      let headerEl;
      if (hideHeader === true) {
        headerEl = null;
      } else {
        headerEl = <Header siteTitle={data.site.siteMetadata.title} />;
      }
      return (
        <>
          {headerEl}
          <div style={{}}>
            <main>{children}</main>
          </div>
          <Footer />
        </>
      );
    }}
  />
);

Layout.propTypes = {
  hideHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Layout;
