import { Link } from "gatsby";
import React from "react";

import styles from "../styles/footer.module.scss";

const Footer = () => (
  <footer className={styles.footerMain}>
    <div className={styles.legalNotice}>
      <p>
        Note that this site is <strong>not legal advice</strong>, but a
        collection of summaries by photographers, for other phtographers. For
        legal advice, please contact the local authoraties or lawyers. If you
        find an error, please refer to the{" "}
        <Link to="/legal-info">Legal Info</Link>.
      </p>
    </div>
  </footer>
);

export default Footer;
